/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

@font-face {
  font-family: Avenir;
  src: url("/assets/fonts/AvenirNextLTPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: AvenirLight;
  src: url("/assets/fonts/AvenirNextLTPro-LightCn.otf") format("opentype");
}

@font-face {
  font-family: AvenirDemi;
  src: url("/assets/fonts/AvenirNextLTPro-Demi.otf") format("opentype");
}

@font-face {
  font-family: AvenirBold;
  src: url("/assets/fonts/AvenirNextLTPro-Bold.otf") format("opentype");
}

@font-face {
  font-family: AvenirPro;
  src: url("/assets/fonts/AvenirNextLTPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: AvenirProThin;
  src: url("/assets/fonts/AvenirNextLTPro-Thin.otf") format("opentype");
}

@font-face {
  font-family: AvenirProMedium;
  src: url("/assets/fonts/AvenirNextLTPro-Medium.otf") format("opentype");
}

@font-face {
  font-family: AvenirProMediumItalic;
  src: url("/assets/fonts/AvenirNextLTPro-MediumIt.otf") format("opentype");
}

/* Workaround to hide android default validation colors */
ion-item.item-md.item-input.input-has-focus .item-inner,
ion-item.item-md.item-input.ng-valid.input-has-value:not(.input-has-focus) .item-inner,
ion-item.item-md.item-input.ng-invalid.ng-touched:not(.input-has-focus) .item-inner {
  border-bottom-color: #dadada;
  box-shadow: none;
}
.native-input[disabled].sc-ion-input-md {
  opacity: 0.9;
}
ion-item {
  --background-focused: transparent !important;
}

/* Workaround to hide window phone default validation colors */
ion-item.item-wp.item-input.input-has-focus .text-input,
ion-item.item-wp.item-input.ng-valid.input-has-value:not(.input-has-focus) .text-input,
ion-item.item-wp.item-input.ng-invalid.ng-touched:not(.input-has-focus) .text-input {
  border: 2px solid #dadada;
}

.list-md.list-inset ion-item:first-child {
  --border-width: 0;
}

$item-md-padding-end: 0;

.followup-modal {
  position: absolute;
  z-index: 20000;
  height: 100%;
  width: 100%;

  ion-backdrop {
    visibility: visible !important;
  }

  .modal-wrapper {
    border-radius: 0;
    overflow: hidden;
    top: 25%;
    left: 33%;
    position: absolute;
    width: 600px;
    height: 510px;
  }
}

.followup-post-it-modal {
  .modal-wrapper {
    height: 475px;
    border-radius: 0;
  }
}

.alert-danger {
  color: --ion-color-danger !important;
}

/**
* Handles the date-picker, md. confirmation & interfaces
**/
// [aria-checked="true"].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
//   color: var(--ion-color-step-850, #262626);
//   font-family: "AvenirPro";
//   font-size: 17px;
// }

.alert-button-group.sc-ion-alert-md {
  margin-bottom: 8px;
}
.sc-ion-alert-md-h {
  border-radius: 0;
  font-family: "Avenir";
}

ion-item {
  --ripple-color: transparent;
  --background-hover: transparent;
}

ion-radio {
  :host {
    border-radius: 0;
    width: 15px !important;
    height: 15px !important;
  }

  .radio-icon {
    border-radius: 0;
    width: 15px !important;
    height: 15px !important;
  }
}

.radio-icon .radio-md {
  border-radius: 0;
  width: 15px !important;
  height: 15px !important;
}

// Sort filter styles

.table-filter {
  margin-bottom: 22px;
  overflow: auto;

  img {
    float: left;
    height: 34px;
  }
}

.alert-head.sc-ion-alert-md {
  font-family: "AvenirDemi";
  text-transform: uppercase;
  font-size: 16px;
  border-bottom: 0;
  padding-bottom: 4px;
}

.alert-radio-label.sc-ion-alert-md {
  font-family: "AvenirDemi";
  font-size: 15px;
}
.alert-button-inner.sc-ion-alert-md {
  height: 30px;
  border-radius: 5px;
}
.alert-radio-icon.sc-ion-alert-md {
  margin-top: 0;
  width: 14px;
  height: 14px;
  margin-right: 8px;
  border: none;
  background-image: url("/assets/button-radio@2x.png");
  border-radius: 0;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
.alert-radio-group.sc-ion-alert-md {
  padding-top: 1rem;
  border-top: none;
  border-bottom: none;
}
.sc-ion-alert-md.alert-radio-label.sc-ion-alert-md {
  padding-left: 35px;
}
.alert-button.sc-ion-alert-md {
  font-family: "AvenirDemi";
  color: white;
  width: auto;
  padding: 0 1rem;
  background-color: #08b4b9;
  // Cancel button
  &:first-child {
    background-color: #d1d1d1;
  }
}

.alert-radio-inner.sc-ion-alert-md {
  left: 0px;
  top: 0px;
  background-image: url("/assets/button-radio@2x.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
.alert-radio-inner.sc-ion-alert-md {
  border-radius: 0;
  border: none;
  display: none;
}
[aria-checked="true"].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
  background-image: url("/assets/button-radio-checked-red@2x.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
.alert-radio-label.sc-ion-alert-md {
  margin-top: 4px;
}
// buttons

button {
  outline: none;
  text-transform: uppercase;
  height: 30px;
  padding: 0 14px;
  font-family: AvenirBold;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  vertical-align: middle;
  letter-spacing: 0.3px;
  text-align: center;
  text-transform: uppercase;
  border: none;
  background: transparent;
  vertical-align: middle;
  line-height: 31px;
  border-radius: 5px;
  color: white;
  &.primary {
    background-color: #08b8be;
    &:hover {
      background-color: #049ca2;
    }
    &.disabled {
      background-color: #e0e0e0;
    }
  }
  &.secondary {
    background-color: #c6c6c6;
    &:hover {
      background-color: #b4b4b4;
    }
  }
  border-style: none;
}

.ion-page {
  @media screen and (orientation: portrait) and (max-width: 1024px) {
    // padding-bottom: 200px;
  }
}

body.backdrop-no-scroll {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
}
