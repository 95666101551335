* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

html {
  width: 100%;
  height: 100%;
  text-size-adjust: 100%;
}

html:not(.hydrated) body {
  display: none;
}

html.plt-pwa {
  height: 100vh;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  text-rendering: optimizeLegibility;
  overflow: hidden;
  touch-action: manipulation;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  word-wrap: break-word;
  overscroll-behavior-y: none;
  text-size-adjust: none;
}

/*# sourceMappingURL=structure.css.map */
