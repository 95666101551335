.ion-text-center,
[text-center] {
  text-align: center !important;
}

.ion-text-justify,
[text-justify] {
  text-align: justify !important;
}

.ion-text-start,
[text-start] {
  text-align: start !important;
}

.ion-text-end,
[text-end] {
  text-align: end !important;
}

.ion-text-left,
[text-left] {
  text-align: left !important;
}

.ion-text-right,
[text-right] {
  text-align: right !important;
}

.ion-text-nowrap,
[text-nowrap] {
  white-space: nowrap !important;
}

.ion-text-wrap,
[text-wrap] {
  white-space: normal !important;
}

@media (min-width: 576px) {
  .ion-text-sm-center,
[text-sm-center] {
    text-align: center !important;
  }

  .ion-text-sm-justify,
[text-sm-justify] {
    text-align: justify !important;
  }

  .ion-text-sm-start,
[text-sm-start] {
    text-align: start !important;
  }

  .ion-text-sm-end,
[text-sm-end] {
    text-align: end !important;
  }

  .ion-text-sm-left,
[text-sm-left] {
    text-align: left !important;
  }

  .ion-text-sm-right,
[text-sm-right] {
    text-align: right !important;
  }

  .ion-text-sm-nowrap,
[text-sm-nowrap] {
    white-space: nowrap !important;
  }

  .ion-text-sm-wrap,
[text-sm-wrap] {
    white-space: normal !important;
  }
}
@media (min-width: 768px) {
  .ion-text-md-center,
[text-md-center] {
    text-align: center !important;
  }

  .ion-text-md-justify,
[text-md-justify] {
    text-align: justify !important;
  }

  .ion-text-md-start,
[text-md-start] {
    text-align: start !important;
  }

  .ion-text-md-end,
[text-md-end] {
    text-align: end !important;
  }

  .ion-text-md-left,
[text-md-left] {
    text-align: left !important;
  }

  .ion-text-md-right,
[text-md-right] {
    text-align: right !important;
  }

  .ion-text-md-nowrap,
[text-md-nowrap] {
    white-space: nowrap !important;
  }

  .ion-text-md-wrap,
[text-md-wrap] {
    white-space: normal !important;
  }
}
@media (min-width: 992px) {
  .ion-text-lg-center,
[text-lg-center] {
    text-align: center !important;
  }

  .ion-text-lg-justify,
[text-lg-justify] {
    text-align: justify !important;
  }

  .ion-text-lg-start,
[text-lg-start] {
    text-align: start !important;
  }

  .ion-text-lg-end,
[text-lg-end] {
    text-align: end !important;
  }

  .ion-text-lg-left,
[text-lg-left] {
    text-align: left !important;
  }

  .ion-text-lg-right,
[text-lg-right] {
    text-align: right !important;
  }

  .ion-text-lg-nowrap,
[text-lg-nowrap] {
    white-space: nowrap !important;
  }

  .ion-text-lg-wrap,
[text-lg-wrap] {
    white-space: normal !important;
  }
}
@media (min-width: 1200px) {
  .ion-text-xl-center,
[text-xl-center] {
    text-align: center !important;
  }

  .ion-text-xl-justify,
[text-xl-justify] {
    text-align: justify !important;
  }

  .ion-text-xl-start,
[text-xl-start] {
    text-align: start !important;
  }

  .ion-text-xl-end,
[text-xl-end] {
    text-align: end !important;
  }

  .ion-text-xl-left,
[text-xl-left] {
    text-align: left !important;
  }

  .ion-text-xl-right,
[text-xl-right] {
    text-align: right !important;
  }

  .ion-text-xl-nowrap,
[text-xl-nowrap] {
    white-space: nowrap !important;
  }

  .ion-text-xl-wrap,
[text-xl-wrap] {
    white-space: normal !important;
  }
}

/*# sourceMappingURL=text-alignment.css.map */
