/*
 * Application global variables.
 */

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

:root {
  /** primary **/
  --ion-color-primary: #00b4b9;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: white;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #019ca2;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #9acc38;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #9acc38;
  --ion-color-secondary-tint: #9acc38;

  /** tertiary **/
  --ion-color-tertiary: #ffcc00;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #ffcc00;
  --ion-color-tertiary-tint: #ffcc00;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #fff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #fff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #fff;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** background **/
  --ion-background-color: #fff;
  --ion-background-color-rgb: 255, 255, 255;
  --ion-background-color-step-50: #f2f2f2;
  --ion-background-color-step-100: #e6e6e6;
  --ion-background-color-step-150: #d9d9d9;
  --ion-background-color-step-200: #ccc;
  --ion-background-color-step-250: #bfbfbf;
  --ion-background-color-step-300: #b3b3b3;
  --ion-background-color-step-350: #a6a6a6;
  --ion-background-color-step-400: #999;
  --ion-background-color-step-450: #8c8c8c;
  --ion-background-color-step-500: #808080;
  --ion-background-color-step-550: #737373;
  --ion-background-color-step-600: #666;
  --ion-background-color-step-650: #595959;
  --ion-background-color-step-700: #4d4d4d;
  --ion-background-color-step-750: #404040;
  --ion-background-color-step-800: #333;
  --ion-background-color-step-850: #262626;
  --ion-background-color-step-900: #191919;
  --ion-background-color-step-950: #0d0d0d;
  --ion-background-color-step-1000: #000;

  --ion-main-button-color-primary: #08b4b9;
  --ion-main-button-color-hover: #019ca2;
  --ion-main-button-color-active-border: #009999;
  --ion-main-button-color-disabled: #e0e0e0;

  --ion-secondary-button-color-primary: #d1d1d1;
  --ion-secondary-button-color-hover: #bebebe;
  --ion-secondary-button-color-active-border: #a2a2a2;
  --ion-secondary-button-color-disabled: #e0e0e0;

  /** content margin and padding **/
  --ion-content-margin: 16px;
  --ion-content-padding: 16px;

  // Test for inputs
  --inner-padding-end: 0;
}
