/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Theme customization
@import "theme/theme";

#toast-container {
  .toast {
    background-color: #030303;
  }

  .toast-success {
    &::before {
      position: absolute;
      margin-left: -30px;
      height: 30px;
      width: 30px;
      background: url("/assets/icon-check@2x.png") center cover no-repeat;
      display: block;
      content: "";
    }
    border-top: 5px solid --ion-color-primary;
    background-color: rgba(255, 255, 255, 0.8) !important;
    color: #3171e0;
    font-family: "Avenir";
    font-size: 16px;
  }

  .toast-error {
    background-color: #bd362f;
  }

  .toast-info {
    background-color: #2f96b4;
  }

  .toast-warning {
    background-color: #f89406;
  }
}

ion-content {
  --offset-bottom: auto !important;
  --overflow: hidden;
  overflow: auto;
  padding-bottom: 10rem;
  &::-webkit-scrollbar {
    display: none;
  }
}
